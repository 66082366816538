import { useState, useEffect } from 'react';
import { Query } from 'shared';
import { useAuthContext } from '../../../hooks';

import { VerticalSteps, ErrorPage, Modal } from '../../shared';
import HeadQuarter from './head_quarter';
import BranchOffices from './branch_office/branch_offices';
import PersonalInfo from './personal_info';

import GeneralSettingPage from './setting';
import Members from './members';
import { subNavigation } from './utils';
import { Button } from '../../../styles/components';
// import { useHistory, useRouteMatch } from 'react-router-dom';

export default function AdminPannel() {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [tokenModal, setTokenModalOpen] = useState(false);

  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { id, headquarter, plan_type, plan_version } = user;
  const isLightProduct = plan_version === 'light';
  const headQuarterId = headquarter ? headquarter.id : undefined;
  const navigationData = !isLightProduct
    ? subNavigation
    : subNavigation.slice(0, -1);

  const { FetchHeadquarterDataById } = Query();
  const handlelogout = () => auth.signout();
  const {
    data: headQuearterData,
    refetch,
    error,
  } = FetchHeadquarterDataById({
    key: `headquarter-${headQuarterId}`,
    id: headQuarterId,
  });
  useEffect(() => {
    //@ts-ignore
    if (error && error.response && error.response.status === 401) {
      setTokenModalOpen(true);
      // localStorage.removeItem('user');
      // localStorage.removeItem('token');
      // auth.signout();
      // window.location.reload();
    }
  }, [error, auth]);

  return (
    <VerticalSteps
      openTab={openTab}
      setOpenTab={setOpenTab}
      subNavigation={navigationData}
      title="Adminbereich">
      <div className="h-full mt-3 px-3 md:px-0 w-screen lg:w-auto lg:overflow-auto md:max-w-2xl lg:max-w-none">
        <div className={openTab === 0 ? 'flex flex-col' : 'hidden'}>
          {/* @ts-ignore */}
          <PersonalInfo id={id} />
        </div>
        <div
          className={openTab === 1 ? 'flex flex-col h-full md:p-6' : 'hidden'}>
          {/* @ts-ignore */}
          <GeneralSettingPage id={id} />
        </div>
        <div className={openTab === 2 ? 'flex items-start flex-col' : 'hidden'}>
          {headQuearterData ? (
            // @ts-ignore
            <HeadQuarter
              productType={plan_type}
              headQuearterData={headQuearterData}
              refetch={refetch}
            />
          ) : (
            <div className={'h-screen flex justify-center w-full'}>
              <ErrorPage />
            </div>
          )}
        </div>

        <div
          className={openTab === 3 ? 'flex flex-col h-full md:p-6' : 'hidden'}>
          <BranchOffices
            headquarterId={headQuearterData ? headQuearterData.id : null}
          />
        </div>

        <div
          className={openTab === 4 ? 'flex flex-col h-full md:p-6' : 'hidden'}>
          <Members />
        </div>

        {/* <div
          className={openTab === 5 ? 'flex flex-col h-full md:p-6' : 'hidden'}>
          <Vehicles />
        </div> */}
      </div>
      <Modal
        setOpen={setTokenModalOpen}
        open={tokenModal}
        title="Ihre Sicherheit ist uns wichtig!"
        subtile="Ihre Zwei-Faktor-Authentifizierung (2FA) ist abgelaufen oder Ihre Sitzung wurde beendet. Bitte melden Sie sich erneut an, um fortzufahren.">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              handlelogout();
              // window.location.reload();
              // history.push(`${url}/login`);
              // console.log('click ok on token modal');
            }}
            label="Jetzt anmelden"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </VerticalSteps>
  );
}
